import React, { Fragment, MouseEventHandler, useState } from "react";
import { Badge, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Mic as MicIcon,
  MicOff as MicOffIcon,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
  VolumeUp as VolumeIcon,
  VolumeOff as VolumeOffIcon,
} from "@mui/icons-material";
import { LocalParticipant } from "twilio-video";
import { sinkIdCompatible } from "../../constants";
import DeviceSelect from "./DeviceSelect";
import { useDevices } from "../../hooks/useDevices";
import { useAudioOutput } from "../../hooks";

const StyledButton = styled(Button)(() => ({
  backgroundColor: "#131519",
  color: "#fff",
  height: 40,
  marginLeft: 2.5,
  marginRight: 2.5,
  width: 20,
}));

export type Props = {
  localParticipant: LocalParticipant;
};

const Controls: React.FC<Props> = (props) => {
  const { localParticipant } = props;

  const { audioInputDevices, audioOutputDevices, videoInputDevices } =
    useDevices();
  const { muted: audioOutputMuted, setMuted: setAudioOutputMuted } =
    useAudioOutput();

  const [shouldAudioBeEnabled, setShouldAudioBeEnabled] =
    useState<boolean>(true);
  const [shouldVideoBeEnabled, setShouldVideoBeEnabled] =
    useState<boolean>(true);

  const handleAudioOutputButtonClick: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    setAudioOutputMuted(!audioOutputMuted);
  };

  const handleAudioButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setShouldAudioBeEnabled((shouldAudioBeEnabled) => {
      const res = !shouldAudioBeEnabled;

      localParticipant.audioTracks.forEach(({ track }) =>
        res ? track.enable() : track.disable()
      );

      return res;
    });
  };

  const handleVideoButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setShouldVideoBeEnabled((shouldVideoBeEnabled) => {
      const res = !shouldVideoBeEnabled;

      localParticipant.videoTracks.forEach(({ track }) =>
        res ? track.enable() : track.disable()
      );

      return res;
    });
  };

  return (
    <Fragment>
      {!shouldAudioBeEnabled && (
        <div className="input-disabled-warning">MICROPHONE MUTED</div>
      )}

      <div className="toolbox">
        <div className="toolbox-btn-group">
          {sinkIdCompatible && (
            <Badge
              badgeContent={
                <DeviceSelect
                  devices={audioOutputDevices}
                  kind="audiooutput"
                  localParticipant={localParticipant}
                  tooltipTitle="Audio Output Settings"
                />
              }
              classes={{
                badge: "toolbox-btn-badge",
              }}
            >
              <Tooltip placement="top" title="Mute/Unmute Output">
                <StyledButton
                  data-testid="toolbox-audio-ouput-enable-button"
                  variant="contained"
                  onClick={handleAudioOutputButtonClick}
                >
                  {audioOutputMuted ? <VolumeOffIcon /> : <VolumeIcon />}
                </StyledButton>
              </Tooltip>
            </Badge>
          )}

          <Badge
            badgeContent={
              <DeviceSelect
                devices={audioInputDevices}
                kind="audioinput"
                localParticipant={localParticipant}
                tooltipTitle="Audio Input Settings"
              />
            }
            classes={{
              badge: "toolbox-btn-badge",
            }}
          >
            <Tooltip placement="top" title="Mute/Unmute Input">
              <StyledButton
                data-testid="toolbox-audio-input-enable-button"
                variant="contained"
                onClick={handleAudioButtonClick}
              >
                {shouldAudioBeEnabled ? <MicIcon /> : <MicOffIcon />}
              </StyledButton>
            </Tooltip>
          </Badge>

          <Badge
            badgeContent={
              <DeviceSelect
                devices={videoInputDevices}
                kind="videoinput"
                localParticipant={localParticipant}
                tooltipTitle="Video Settings"
              />
            }
            classes={{
              badge: "toolbox-btn-badge",
            }}
          >
            <Tooltip placement="top" title="Start / Stop Camera">
              <StyledButton
                data-testid="toolbox-video-enable-button"
                variant="contained"
                onClick={handleVideoButtonClick}
              >
                {shouldVideoBeEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
              </StyledButton>
            </Tooltip>
          </Badge>
        </div>
      </div>
    </Fragment>
  );
};

export default Controls;
