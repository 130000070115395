import type { TwilioError } from "twilio-video";

import { useCallback, useEffect, useRef, useState } from "react";
import { ConnectOptions, Room, connect } from "twilio-video";
import { messaging } from "../constants";

export const useRoom = (options?: ConnectOptions) => {
  const [connectionError, setConnectionError] = useState<TwilioError | null>(
    null
  );
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [room, setRoom] = useState<Room | null>(null);

  const optionsRef = useRef<ConnectOptions>(options ?? {});

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object.
    optionsRef.current = options ?? {};
  }, [options]);

  const connectToRoom = useCallback(
    async (accessToken: string, roomName: string) => {
      setIsConnecting(true);

      try {
        console.log(`Connecting to room with options: ${optionsRef.current}`);

        const room = await connect(accessToken, {
          ...optionsRef.current,
          automaticSubscription: false,
          name: roomName,
        });

        setRoom(room);
        setConnectionError(null);
      } catch (err: any) {
        let errorToSet: TwilioError;

        if (err) {
          errorToSet = err;
          errorToSet.message = `${messaging.ConnectionErrorPrefix}${err.message}`;
        } else {
          const errorObj = new Error(
            `${messaging.ConnectionErrorPrefix}${"Unknown"}`
          );

          Object.defineProperty(errorObj, "code", { value: -1 });

          errorToSet = errorObj as TwilioError;
        }

        console.warn(errorToSet);
        setConnectionError(errorToSet);
      }

      setIsConnecting(false);
    },
    []
  );

  const disconnect = useCallback(() => {
    if (room) {
      room.removeAllListeners();
      room.disconnect();

      setRoom(null);
    }
  }, [room]);

  return {
    connect: connectToRoom,
    connectionError,
    disconnect,
    isConnecting,
    room,
  };
};
