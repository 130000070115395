import { useEffect, useState } from "react";
import { Devices } from "../types";

export const useDevices = () => {
  const [deviceInfo, setDeviceInfo] = useState<Devices>({
    audioInputDevices: [],
    audioOutputDevices: [],
    videoInputDevices: [],
  });

  useEffect(() => {
    const getDevices = async (): Promise<void> => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      setDeviceInfo({
        audioInputDevices: devices.filter(
          (device) => device.kind === "audioinput"
        ),
        videoInputDevices: devices.filter(
          (device) => device.kind === "videoinput"
        ),
        audioOutputDevices: devices.filter(
          (device) => device.kind === "audiooutput"
        ),
      });
    };

    async function deviceChangeListener() {
      console.log(
        "Device change detected. Obtaining list of possible input/output devices..."
      );

      await getDevices();
    }

    navigator.mediaDevices.addEventListener(
      "devicechange",
      deviceChangeListener
    );

    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        deviceChangeListener
      );
    };
  }, []);

  return deviceInfo;
};
