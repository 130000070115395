import { ConnectionErrorName, MediaErrorName, MessageName } from "./types";

export const sinkIdCompatible = "setSinkId" in HTMLMediaElement.prototype;

// https://www.twilio.com/docs/api/errors
export const connectionErrorCodes: Record<ConnectionErrorName, number> = {
  RoomNotFoundError: 53106,
  UnableToCreateRoomError: 53103,
};

export const mediaErrorsAndSolutions: Record<MediaErrorName, string> = {
  NotAllowedError:
    "Cannot access your camera and/or microphone.  Please make sure you have given your browser permission to access these devices, and then reload this page.",
  NotFoundError:
    "Cannot detect your camera and/or microphone.  Please make sure these devices are connected and that your browser has permission to access them, and then reload this page.",
  NotReadableError:
    "Cannot access your camera.  Please make sure no other applications are using your camera, and then reload this page.",
  OverconstrainedError:
    "Cannot detect your camera.  Please make sure your device is connected and that your browser has permission to access it, and then reload this page.",
  TypeError:
    "Cannot detect your camera and/or microphone.  Please make sure you are using the most up-to-date operating system and WebRTC-compatible browser for your device.",
};

export const messaging: Record<MessageName, string> = {
  Connecting: "Connecting to the room: this may take a few seconds...",
  ConnectionErrorPrefix: "Failed to connect to video room with error: ",
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "VCCWebRTC-selectedAudioInput";
export const SELECTED_VIDEO_INPUT_KEY = "VCCWebRTC-selectedVideoInput";

export enum CustomLocalParticipantEvent {
  LocalTrackChange = "localTrackChange",
}
