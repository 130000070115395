import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Resizable } from "re-resizable";
import { Grid, Tooltip } from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  VideocamOff as VideocamOffIcon,
} from "@mui/icons-material";
import DisabledVideoOverlay from "./DisabledVideoOverlay";
import {
  LocalAudioTrackPublication,
  LocalParticipant as VideoLocalParticipant,
  LocalVideoTrackPublication,
} from "twilio-video";
import { useTracks } from "../../hooks/useTracks";
import { trackPubsToTracks } from "../../helpers";
import { CustomLocalParticipantEvent } from "../../constants";

type Props = {
  localParticipant: VideoLocalParticipant;
};

const LocalParticipant: FunctionComponent<Props> = (props) => {
  const { localParticipant } = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoTrack, setVideoTrack] = useState(
    trackPubsToTracks(localParticipant.videoTracks)[0]
  );

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current as HTMLMediaElement);

      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    if (localParticipant) {
      const handleLocalTrackChanged = (
        trackPub: LocalAudioTrackPublication | LocalVideoTrackPublication
      ) => {
        if (trackPub.kind === "video") {
          setVideoTrack(trackPub.track);
        }
      };

      localParticipant.on(
        CustomLocalParticipantEvent.LocalTrackChange,
        handleLocalTrackChanged
      );

      return () => {
        localParticipant.off(
          CustomLocalParticipantEvent.LocalTrackChange,
          handleLocalTrackChanged
        );
      };
    }
  }, [localParticipant]);

  const { isVideoEnabled } = useTracks(localParticipant, true);

  return (
    <div
      style={{
        height: "100%",
        position: "absolute",
        width: "100%",
        zIndex: 10,
        margin: 10,
      }}
    >
      <Resizable
        bounds="parent"
        defaultSize={{ height: "20%", width: "20%" }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleComponent={{
          right: (
            <Tooltip placement="right" title="Drag to resize">
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="space-around"
                style={{
                  background: "darkgray",
                  border: "1px solid darkgray",
                  borderRadius: "3px",
                  height: "100%",
                  paddingLeft: "3px",
                  width: "16px",
                  zIndex: 11,
                }}
              >
                <Grid item>
                  <MoreVertIcon />
                </Grid>
              </Grid>
            </Tooltip>
          ),
        }}
        lockAspectRatio={true}
        maxHeight={isMobile ? "75%" : "85%"}
        maxWidth={"95%"}
        minWidth={"10%"}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <video
            autoPlay
            data-testid="local-video"
            muted
            playsInline
            ref={videoRef}
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "scale(-1, 1)",
            }}
          />

          {!isVideoEnabled && (
            <DisabledVideoOverlay>
              <VideocamOffIcon />
              <p>You have disabled your camera.</p>
            </DisabledVideoOverlay>
          )}
        </div>
      </Resizable>
    </div>
  );
};

export default LocalParticipant;
