import { useMemo } from "react";
import { BandwidthProfileMode, ConnectOptions } from "twilio-video";
import { Perspective } from "../types";
import { isMobile } from "react-device-detect";

export const useRecommendedConnectionProfile = (
  perspective: Perspective,
  mode: BandwidthProfileMode
) =>
  useMemo(() => {
    const connectionProfile: ConnectOptions = {
      preferredAudioCodecs: ["opus"],
      preferredVideoCodecs: [
        { codec: "VP8", simulcast: perspective === Perspective.presenter },
      ],
      maxAudioBitrate: 16000,
      networkQuality: { local: 1, remote: 1 },
      audio: {
        echoCancellation: true,
        autoGainControl: false,
        latency: 0,
        noiseSuppression: true,
        sampleRate: 48000,
        sampleSize: 16,
      },
      video: {
        width: isMobile ? 640 : 1280,
        height: isMobile ? 480 : 720,
        frameRate: 24,
      },
      bandwidthProfile: {
        video: {
          clientTrackSwitchOffControl: "manual",
          contentPreferencesMode: "manual",
          mode: mode,
        },
      },
    };

    // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
    if (isMobile && connectionProfile?.bandwidthProfile?.video) {
      connectionProfile!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
    }

    return connectionProfile;
  }, [perspective, mode]);
