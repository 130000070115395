import { useEffect, useRef, useState } from "react";
import { AudioTrack, Participant, VideoTrack } from "twilio-video";
import { trackPubsToTracks } from "../helpers";

export const useTracks = (
  participant: Participant,
  shouldDisplayVideo: boolean
) => {
  const [videoTracks, setVideoTracks] = useState<VideoTrack[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks) as VideoTrack[]);
    setAudioTracks(trackPubsToTracks(participant.audioTracks) as AudioTrack[]);

    const trackSubscribed = (track: VideoTrack | AudioTrack) => {
      if (track.kind === "video" && shouldDisplayVideo) {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: VideoTrack | AudioTrack) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, shouldDisplayVideo]);

  useEffect(() => {
    const [videoTrack] = videoTracks;

    if (videoTrack) {
      videoTrack.attach(videoRef.current as HTMLMediaElement);

      setIsVideoEnabled(videoTrack.isEnabled);

      const handleTrackEnabled = () => setIsVideoEnabled(true);
      const handleTrackDisabled = () => setIsVideoEnabled(false);

      videoTrack.on("enabled", handleTrackEnabled);
      videoTrack.on("disabled", handleTrackDisabled);

      return () => {
        videoTrack.detach();

        videoTrack.off("enabled", handleTrackEnabled);
        videoTrack.off("disabled", handleTrackDisabled);
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const [audioTrack] = audioTracks;

    if (audioTrack) {
      audioTrack.attach(audioRef.current as HTMLMediaElement);

      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return {
    audioRef,
    audioTracks,
    isVideoEnabled,
    videoRef,
    videoTracks,
  };
};
