import React, { Fragment, FunctionComponent, useState } from "react";
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalParticipant,
  LocalTrack,
} from "twilio-video";
import { CustomLocalParticipantEvent } from "../../constants";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import { trackPubsToTracks } from "../../helpers";
import { useAudioOutput } from "../../hooks";

export type Props = {
  devices: MediaDeviceInfo[];
  kind: MediaDeviceKind;
  localParticipant: LocalParticipant;
  tooltipTitle: string;
};

const DeviceSelect: FunctionComponent<Props> = (props) => {
  const { devices, kind, localParticipant, tooltipTitle } = props;

  const audioOutput = useAudioOutput();

  // default to "audiooutput" case
  let currentDeviceId: string | undefined = audioOutput.deviceId ?? "";

  if (kind === "audioinput") {
    currentDeviceId = trackPubsToTracks(
      localParticipant.audioTracks
    )[0]?.mediaStreamTrack.getSettings().deviceId;
  } else if (kind === "videoinput") {
    currentDeviceId = trackPubsToTracks(
      localParticipant.videoTracks
    )[0]?.mediaStreamTrack.getSettings().deviceId;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [isSwitchingDevice, setIsSwitchingDevice] = useState<boolean>(false);

  async function handleMenuItemClick(deviceId: string) {
    try {
      setIsSwitchingDevice(true);

      if (kind === "audiooutput") {
        audioOutput.setDeviceId(deviceId);
      } else {
        let tracks: unknown[];
        if (kind === "audioinput") {
          tracks = trackPubsToTracks(localParticipant.audioTracks);
          tracks.forEach((track: any) => track.stop());
        } else {
          tracks = trackPubsToTracks(localParticipant.videoTracks);
        }

        localParticipant.unpublishTracks(tracks as unknown as LocalTrack[]);

        let localTrack: LocalTrack;
        if (kind === "audioinput") {
          localTrack = await createLocalAudioTrack({
            deviceId: { exact: deviceId },
          });
        } else {
          localTrack = await createLocalVideoTrack({
            deviceId: { exact: deviceId },
          });
        }

        const localTrackPub = await localParticipant.publishTrack(localTrack);
        localParticipant.emit(
          CustomLocalParticipantEvent.LocalTrackChange,
          localTrackPub
        );
      }
    } catch (err) {
      console.warn(err);
    }

    setIsSwitchingDevice(false);
  }

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const firstDeviceOption = devices[0];

  return (
    <Fragment>
      <Tooltip placement="top" title={tooltipTitle}>
        <Button
          data-testid={`toolbox-settings-button-${tooltipTitle}`}
          disabled={devices.length === 0}
          sx={{
            backgroundColor: "#36383c",
            color: "#fff",
            maxHeight: 17.5,
            maxWidth: 15,
            height: 17.5,
            width: 15,
          }}
          variant="contained"
          onClick={handleClick}
        >
          <KeyboardArrowUpIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        data-testid={"settings-menu"}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {devices
          .sort((a, b) => {
            const labelA = a.label.toUpperCase(); // ignore upper and lowercase
            const labelB = b.label.toUpperCase(); // ignore upper and lowercase

            if (labelA < labelB) {
              return -1;
            } else if (labelA > labelB) {
              return 1;
            }

            return 0;
          })
          .map(({ deviceId, label }) => {
            const isFirstDeviceOption =
              deviceId === firstDeviceOption?.deviceId;
            const disabled =
              isSwitchingDevice ||
              currentDeviceId === deviceId ||
              (currentDeviceId === "" && isFirstDeviceOption);

            return (
              <MenuItem
                data-testid={deviceId}
                disabled={disabled}
                key={deviceId}
                onClick={() => handleMenuItemClick(deviceId)}
              >
                {label}
              </MenuItem>
            );
          })}
      </Menu>
    </Fragment>
  );
};

export default DeviceSelect;
