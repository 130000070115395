import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ConnectOptions, Logger } from "twilio-video";
import Toolbox from "./Toolbox";
import LocalParticipant from "./LocalParticipant";
import RemoteParticipant from "./RemoteParticipant";
import { Perspective } from "../../types";
import { determineShouldRetryConnection } from "../../helpers";
import { messaging } from "../../constants";
import {
  useParticipants,
  usePrevious,
  useRecommendedConnectionProfile,
  useRoom,
} from "../../hooks";

const retryInMillis = 5000;
const logger = Logger.getLogger("twilio-video");
logger.setLevel("info");

type Props = {
  accessToken: string;
  connectOptions?: ConnectOptions;
  noCmp?: boolean;
  perspective: Perspective;
  remoteParticipantVolume: number;
  remotePresenterVolume: number;
  roomName: string;
};

const Room: React.FC<Props> = (props) => {
  const {
    accessToken,
    connectOptions,
    noCmp = false,
    remoteParticipantVolume,
    remotePresenterVolume,
    roomName,
    perspective,
  } = props;

  const previousAccessToken = usePrevious(accessToken);

  const connectionProfile =
    connectOptions ??
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRecommendedConnectionProfile(perspective, "presentation");

  const { connect, connectionError, disconnect, isConnecting, room } =
    useRoom(connectionProfile);

  const { participants, presenterParticipant } = useParticipants(room);

  const shouldRetryConnection = connectionError
    ? determineShouldRetryConnection(connectionError)
    : false;
  const shouldConnect = !room && (!connectionError || shouldRetryConnection);
  const shouldReconnect =
    room && previousAccessToken && accessToken !== previousAccessToken;

  useEffect(() => {
    const hookName = "Room connection effect hook";
    console.log(`${hookName}: triggered.`);

    if (
      (shouldConnect || shouldReconnect) &&
      !isConnecting &&
      accessToken &&
      roomName
    ) {
      if (shouldConnect) {
        if (shouldRetryConnection) {
          const timeout = setTimeout(() => {
            console.log(`${hookName}: invoking connect (timeout).`);
            connect(accessToken, roomName);
            clearTimeout(timeout);
          }, retryInMillis);
        } else {
          console.log(`${hookName}: invoking connect.`);
          connect(accessToken, roomName);
        }
      } else {
        // This will retrigger the effect with room being falsy, thus the invoking call to connect above
        console.log(`${hookName}: invoking disconnect to retrigger hook.`);
        disconnect();
      }
    }
  }, [
    accessToken,
    connect,
    disconnect,
    isConnecting,
    roomName,
    shouldConnect,
    shouldRetryConnection,
    shouldReconnect,
  ]);

  useEffect(() => {
    const hookName = "Room watching effect hook";
    console.log(`${hookName}: triggered.`);

    if (room) {
      return () => {
        console.log(`${hookName}: invoking disconnect from cleanup function.`);

        disconnect();
      };
    } else {
      return () => {
        console.log(`${hookName}: empty cleanup function was called.`);
      };
    }
  }, [disconnect, room]);

  const messageStyle: React.CSSProperties = {
    color: "lightgrey",
  };

  const hasErrors = Boolean(connectionError?.message);

  return (
    <div
      className={
        perspective === Perspective.participant
          ? "video-room-container"
          : "fullscreen"
      }
    >
      {!room && (!hasErrors || shouldRetryConnection) && (
        <div className="connecting-container">
          <strong style={messageStyle}>{messaging.Connecting}</strong>
          <CircularProgress />
        </div>
      )}
      {!room && hasErrors && !shouldRetryConnection && (
        <strong style={messageStyle}>{connectionError?.message}</strong>
      )}
      {room?.localParticipant &&
        (perspective === Perspective.participant || noCmp) && (
          <LocalParticipant
            key={room.localParticipant.sid}
            localParticipant={room.localParticipant}
          />
        )}
      {perspective === Perspective.participant && presenterParticipant && (
        <RemoteParticipant
          isPresenter={true}
          key={presenterParticipant.sid}
          participant={presenterParticipant}
          perspective={perspective}
          volume={remotePresenterVolume}
        />
      )}
      {participants
        .filter((participant) => participant.identity.startsWith("participant"))
        .map((participant) => (
          <RemoteParticipant
            key={participant.sid}
            participant={participant}
            perspective={perspective}
            volume={remoteParticipantVolume}
          />
        ))}
      {room?.localParticipant &&
        (perspective === Perspective.participant || noCmp) && (
          <Toolbox localParticipant={room.localParticipant} />
        )}
      {room &&
        perspective === Perspective.presenter &&
        participants.length < 1 && (
          <Box
            sx={{
              display: "inline-flex",
              position: "absolute",
              left: 28,
              bottom: 20,
              "& > *": {
                marginRight: 5,
              },
            }}
          >
            <CircularProgress />
            <Typography variant="h4">Waiting for Participant...</Typography>
          </Box>
        )}
    </div>
  );
};

export default Room;
