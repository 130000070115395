import { useEffect, useState } from "react";
import { RemoteParticipant, Room } from "twilio-video";

export const useParticipants = (room: Room | null) => {
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) => {
          // prevent duplicates
          const filtered = prevParticipants.filter(
            (p) => p.sid !== participant.sid
          );
          return [...filtered, participant];
        });
      };

      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);

      return () => {
        room.off("participantConnected", participantConnected);
        room.off("participantDisconnected", participantDisconnected);
        setParticipants([]);
      };
    }
  }, [room]);

  const presenterParticipant = participants.find((participant) =>
    participant.identity.startsWith("presenter")
  );

  return {
    participants,
    presenterParticipant,
  };
};
