import {
  AudioTrackPublication,
  TwilioError,
  VideoTrackPublication,
} from "twilio-video";
import { connectionErrorCodes } from "./constants";

export const trackPubsToTracks = (
  trackMap: Map<string, AudioTrackPublication | VideoTrackPublication>
) =>
  Array.from(trackMap.values())
    .map(({ track }) => track!)
    .filter((track) => track !== null);

export const determineShouldRetryConnection = (error: TwilioError): boolean =>
  error.code === connectionErrorCodes.RoomNotFoundError ||
  error.code === connectionErrorCodes.UnableToCreateRoomError;
